<template>
  <section class="section">
    <div class="section-header">
      <h1>{{ $route.name }}</h1>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between">
            <h4>All Tasks</h4>
            <router-link
              v-if="userType == 'admin'"
              to="/tasks/create"
              class="btn btn-icon icon-left btn-primary"
              ><i class="far fa-plus"></i> Create Tasks</router-link
            >
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <tr>
                  <th>#</th>
                  <th>Task Name</th>
                  <th>Progress</th>
                  <th>Members</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr v-for="(item, index) in tasks" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.title }}</td>
                  <td class="align-middle">
                    <div
                      class="progress"
                      data-height="4"
                      data-toggle="tooltip"
                      title=""
                      :data-original-title="`width:${item.status == 'completed' ? '100' : item.completed_task}%`"
                      style="height: 4px"
                    >
                      <div
                        class="progress-bar bg-success"
                        data-width="50"
                        :style="`width:${item.status == 'completed' ? '100' : item.completed_task}%`"
                      ></div>
                    </div>
                  </td>
                  <td>
                    {{ item.assign_to }}
                  </td>
                  <td>{{ $globalFunc.getDate(item.due_date) }}</td>
                  <td>
                    <div class="badge badge-info" v-if="new Date(item.start_date) > new Date() && item.status !== 'completed'">
                      Todo
                    </div>
                    <div class="" v-else>
                      <div class="badge badge-success" v-if="item.status == 'completed'">
                        Completed
                      </div>
                      <div class="badge badge-warning" v-else>
                        In progress
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href="#" @click.prevent="changeRoute(item.id)" class="btn btn-secondary">Detail</a>
                  </td>
                </tr>
              </table>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <h6>
                <strong>Total Tasks:</strong> {{ tasks.length }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TaskHelpers from "@/functions/actions/TaskRequest";
import { mapState } from "vuex";
export default {
  name: "Tasks",
  computed: {
    ...mapState(['loading', 'userType']),
  },
  mounted() {
    this.fetchTasks();
  },
  data() {
    return {
      tasks: [],
    };
  },
  methods: {
    changeRoute(taskId) {
      this.$router.push({
        name: 'View Task',
        params: { taskId }
      })
    },
    async fetchTasks() {
      const response = await TaskHelpers.getTasks();
      if (response.status) {
        this.tasks = response.tasks;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>